import { render, staticRenderFns } from "./MintersModal.vue?vue&type=template&id=191a15da&"
import script from "./MintersModal.vue?vue&type=script&lang=js&"
export * from "./MintersModal.vue?vue&type=script&lang=js&"
import style0 from "./MintersModal.vue?vue&type=style&index=0&id=191a15da&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBtn: require('/tmp/build_6ab7ec6a/components/base/BaseBtn.vue').default,BaseFeedbackError: require('/tmp/build_6ab7ec6a/components/base/BaseFeedbackError.vue').default})
