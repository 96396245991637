import { render, staticRenderFns } from "./BaseSidebarItem.vue?vue&type=template&id=61fa5b9e&"
import script from "./BaseSidebarItem.vue?vue&type=script&lang=js&"
export * from "./BaseSidebarItem.vue?vue&type=script&lang=js&"
import style0 from "./BaseSidebarItem.vue?vue&type=style&index=0&id=61fa5b9e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIconNew: require('/tmp/build_6ab7ec6a/components/base/BaseIconNew.vue').default,BaseNotificationBadge: require('/tmp/build_6ab7ec6a/components/base/BaseNotificationBadge.vue').default})
