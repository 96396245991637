import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3a16d916 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _5402ff18 = () => interopDefault(import('../pages/account/activity.vue' /* webpackChunkName: "pages/account/activity" */))
const _27333253 = () => interopDefault(import('../pages/account/activity/_slug.vue' /* webpackChunkName: "pages/account/activity/_slug" */))
const _5f6736a0 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _12c71e2c = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _5293fb85 = () => interopDefault(import('../pages/account/settings/general.vue' /* webpackChunkName: "pages/account/settings/general" */))
const _78f429c4 = () => interopDefault(import('../pages/account/settings/paymentmethod.vue' /* webpackChunkName: "pages/account/settings/paymentmethod" */))
const _0703397c = () => interopDefault(import('../pages/account/wallet.vue' /* webpackChunkName: "pages/account/wallet" */))
const _9eb23d50 = () => interopDefault(import('../pages/account/wallet/mymints.vue' /* webpackChunkName: "pages/account/wallet/mymints" */))
const _35d46223 = () => interopDefault(import('../pages/account/wallet/mytokens.vue' /* webpackChunkName: "pages/account/wallet/mytokens" */))
const _9506097e = () => interopDefault(import('../pages/account/wallet/mytokenshistory.vue' /* webpackChunkName: "pages/account/wallet/mytokenshistory" */))
const _0ea5889e = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _2d5e453a = () => interopDefault(import('../pages/index_old.vue' /* webpackChunkName: "pages/index_old" */))
const _7eba0d33 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _430aed44 = () => interopDefault(import('../pages/messages/_id.vue' /* webpackChunkName: "pages/messages/_id" */))
const _689934ab = () => interopDefault(import('../pages/musicians.vue' /* webpackChunkName: "pages/musicians" */))
const _2b14a543 = () => interopDefault(import('../pages/redeem.vue' /* webpackChunkName: "pages/redeem" */))
const _2c32ec6f = () => interopDefault(import('../pages/redirecting.vue' /* webpackChunkName: "pages/redirecting" */))
const _cfc3a490 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _66d8d8a6 = () => interopDefault(import('../pages/kyc/pending.vue' /* webpackChunkName: "pages/kyc/pending" */))
const _9d32a2d6 = () => interopDefault(import('../pages/kyc/redirecting.vue' /* webpackChunkName: "pages/kyc/redirecting" */))
const _37965f1a = () => interopDefault(import('../pages/legal/terms.vue' /* webpackChunkName: "pages/legal/terms" */))
const _db2a5dc8 = () => interopDefault(import('../pages/market/preview.vue' /* webpackChunkName: "pages/market/preview" */))
const _96c38bbc = () => interopDefault(import('../pages/market/preview/collections.vue' /* webpackChunkName: "pages/market/preview/collections" */))
const _dec431b8 = () => interopDefault(import('../pages/market/preview/favorites.vue' /* webpackChunkName: "pages/market/preview/favorites" */))
const _d87f5850 = () => interopDefault(import('../pages/market/preview/marketplace.vue' /* webpackChunkName: "pages/market/preview/marketplace" */))
const _6fd512c5 = () => interopDefault(import('../pages/market/preview/mybids.vue' /* webpackChunkName: "pages/market/preview/mybids" */))
const _895ff8e4 = () => interopDefault(import('../pages/market/preview/myofferings.vue' /* webpackChunkName: "pages/market/preview/myofferings" */))
const _1c3a950e = () => interopDefault(import('../pages/market/preview/assets/related/_involved.vue' /* webpackChunkName: "pages/market/preview/assets/related/_involved" */))
const _fd883098 = () => interopDefault(import('../pages/market/preview/assets/_collection.vue' /* webpackChunkName: "pages/market/preview/assets/_collection" */))
const _6ede0584 = () => interopDefault(import('../pages/my-tokens/mint-form.vue' /* webpackChunkName: "pages/my-tokens/mint-form" */))
const _4c37ae4c = () => interopDefault(import('../pages/resources/image.vue' /* webpackChunkName: "pages/resources/image" */))
const _75492963 = () => interopDefault(import('../pages/session/forgot.vue' /* webpackChunkName: "pages/session/forgot" */))
const _5381a359 = () => interopDefault(import('../pages/session/login.vue' /* webpackChunkName: "pages/session/login" */))
const _690cb4d8 = () => interopDefault(import('../pages/session/signup.vue' /* webpackChunkName: "pages/session/signup" */))
const _2475dd28 = () => interopDefault(import('../pages/stripe/onboarding/minter.vue' /* webpackChunkName: "pages/stripe/onboarding/minter" */))
const _38c819ce = () => interopDefault(import('../pages/stripe/payment/cancel.vue' /* webpackChunkName: "pages/stripe/payment/cancel" */))
const _244fdc92 = () => interopDefault(import('../pages/stripe/payment/refresh.vue' /* webpackChunkName: "pages/stripe/payment/refresh" */))
const _16202a42 = () => interopDefault(import('../pages/stripe/payment/success.vue' /* webpackChunkName: "pages/stripe/payment/success" */))
const _3994faa5 = () => interopDefault(import('../pages/market/collection/_id.vue' /* webpackChunkName: "pages/market/collection/_id" */))
const _6eac1ef1 = () => interopDefault(import('../pages/market/product/_id/index.vue' /* webpackChunkName: "pages/market/product/_id/index" */))
const _20f72673 = () => interopDefault(import('../pages/my-tokens/list-form/_id.vue' /* webpackChunkName: "pages/my-tokens/list-form/_id" */))
const _00644957 = () => interopDefault(import('../pages/password/reset/_token.vue' /* webpackChunkName: "pages/password/reset/_token" */))
const _564acef7 = () => interopDefault(import('../pages/market/product/_id/checkout.vue' /* webpackChunkName: "pages/market/product/_id/checkout" */))
const _9631c50a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _50a57494 = () => interopDefault(import('../pages/_usernick.vue' /* webpackChunkName: "pages/_usernick" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _3a16d916,
    name: "account___en",
    children: [{
      path: "activity",
      component: _5402ff18,
      name: "account-activity___en",
      children: [{
        path: ":slug?",
        component: _27333253,
        name: "account-activity-slug___en"
      }]
    }, {
      path: "profile",
      component: _5f6736a0,
      name: "account-profile___en"
    }, {
      path: "settings",
      component: _12c71e2c,
      name: "account-settings___en",
      children: [{
        path: "general",
        component: _5293fb85,
        name: "account-settings-general___en"
      }, {
        path: "paymentmethod",
        component: _78f429c4,
        name: "account-settings-paymentmethod___en"
      }]
    }, {
      path: "wallet",
      component: _0703397c,
      name: "account-wallet___en",
      children: [{
        path: "mymints",
        component: _9eb23d50,
        name: "account-wallet-mymints___en"
      }, {
        path: "mytokens",
        component: _35d46223,
        name: "account-wallet-mytokens___en"
      }, {
        path: "mytokenshistory",
        component: _9506097e,
        name: "account-wallet-mytokenshistory___en"
      }]
    }]
  }, {
    path: "/error",
    component: _0ea5889e,
    name: "error___en"
  }, {
    path: "/index_old",
    component: _2d5e453a,
    name: "index_old___en"
  }, {
    path: "/messages",
    component: _7eba0d33,
    name: "messages___en",
    children: [{
      path: ":id?",
      component: _430aed44,
      name: "messages-id___en"
    }]
  }, {
    path: "/musicians",
    component: _689934ab,
    name: "musicians___en"
  }, {
    path: "/redeem",
    component: _2b14a543,
    name: "redeem___en"
  }, {
    path: "/redirecting",
    component: _2c32ec6f,
    name: "redirecting___en"
  }, {
    path: "/support",
    component: _cfc3a490,
    name: "support___en"
  }, {
    path: "/kyc/pending",
    component: _66d8d8a6,
    name: "kyc-pending___en"
  }, {
    path: "/kyc/redirecting",
    component: _9d32a2d6,
    name: "kyc-redirecting___en"
  }, {
    path: "/legal/terms",
    component: _37965f1a,
    name: "legal-terms___en"
  }, {
    path: "/market/preview",
    component: _db2a5dc8,
    name: "market-preview___en",
    children: [{
      path: "collections",
      component: _96c38bbc,
      name: "market-preview-collections___en"
    }, {
      path: "favorites",
      component: _dec431b8,
      name: "market-preview-favorites___en"
    }, {
      path: "marketplace",
      component: _d87f5850,
      name: "market-preview-marketplace___en"
    }, {
      path: "mybids",
      component: _6fd512c5,
      name: "market-preview-mybids___en"
    }, {
      path: "myofferings",
      component: _895ff8e4,
      name: "market-preview-myofferings___en"
    }, {
      path: "assets/related/:involved?",
      component: _1c3a950e,
      name: "market-preview-assets-related-involved___en"
    }, {
      path: "assets/:collection?",
      component: _fd883098,
      name: "market-preview-assets-collection___en"
    }]
  }, {
    path: "/my-tokens/mint-form",
    component: _6ede0584,
    name: "my-tokens-mint-form___en"
  }, {
    path: "/resources/image",
    component: _4c37ae4c,
    name: "resources-image___en"
  }, {
    path: "/session/forgot",
    component: _75492963,
    name: "session-forgot___en"
  }, {
    path: "/session/login",
    component: _5381a359,
    name: "session-login___en"
  }, {
    path: "/session/signup",
    component: _690cb4d8,
    name: "session-signup___en"
  }, {
    path: "/stripe/onboarding/minter",
    component: _2475dd28,
    name: "stripe-onboarding-minter___en"
  }, {
    path: "/stripe/payment/cancel",
    component: _38c819ce,
    name: "stripe-payment-cancel___en"
  }, {
    path: "/stripe/payment/refresh",
    component: _244fdc92,
    name: "stripe-payment-refresh___en"
  }, {
    path: "/stripe/payment/success",
    component: _16202a42,
    name: "stripe-payment-success___en"
  }, {
    path: "/market/collection/:id?",
    component: _3994faa5,
    name: "market-collection-id___en"
  }, {
    path: "/market/product/:id",
    component: _6eac1ef1,
    name: "market-product-id___en"
  }, {
    path: "/my-tokens/list-form/:id?",
    component: _20f72673,
    name: "my-tokens-list-form-id___en"
  }, {
    path: "/password/reset/:token?",
    component: _00644957,
    name: "password-reset-token___en"
  }, {
    path: "/market/product/:id?/checkout",
    component: _564acef7,
    name: "market-product-id-checkout___en"
  }, {
    path: "/",
    component: _9631c50a,
    name: "index___en"
  }, {
    path: "/:usernick",
    component: _50a57494,
    name: "usernick___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
