import { render, staticRenderFns } from "./LayoutTopBarWalletBtn.vue?vue&type=template&id=51a07d07&"
import script from "./LayoutTopBarWalletBtn.vue?vue&type=script&lang=js&"
export * from "./LayoutTopBarWalletBtn.vue?vue&type=script&lang=js&"
import style0 from "./LayoutTopBarWalletBtn.vue?vue&type=style&index=0&id=51a07d07&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/tmp/build_6ab7ec6a/components/base/BaseIcon.vue').default,AccountWalletCard: require('/tmp/build_6ab7ec6a/components/account/wallet/AccountWalletCard.vue').default,AccountSendFundsModal: require('/tmp/build_6ab7ec6a/components/account/wallet/AccountSendFundsModal.vue').default,AccountAddFundsModal: require('/tmp/build_6ab7ec6a/components/account/wallet/AccountAddFundsModal.vue').default})
